import Vue from 'vue'
import VueI18n from 'vue-i18n'
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN'
import elementZhTwLocale from 'element-ui/lib/locale/lang/zh-TW'
import elementEnLocale from 'element-ui/lib/locale/lang/en'
import elementJaLocale from 'element-ui/lib/locale/lang/ja'
import elementKoLocale from 'element-ui/lib/locale/lang/ko'
// import zhLocale from './zh'
// import zhTwLocale from './zh_tw'
// import enLocale from './en'
// import jaLocale from './ja'
// import koLocale from './ko'
import axios from 'axios'

const ax = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 30000,
  responseType: 'json'
})

Vue.use(VueI18n)

// const messages = {
//   zh: {
//     ...zhLocale,
//     ...elementZhLocale
//   },
//   zh_tw: {
//     ...zhTwLocale,
//     ...elementZhTwLocale
//   },
//   en: {
//     ...enLocale,
//     ...elementEnLocale
//   },
//   ja: {
//     ...jaLocale,
//     ...elementJaLocale
//   },
//   ko: {
//     ...koLocale,
//     ...elementKoLocale
//   },
// }

// 系统支持的语言
const supportLans = [
  {
    value: 'sc',
    label: '简体中文'
  },
  {
    value: 'tc',
    label: '繁體中文'
  },
  {
    value: 'en',
    label: 'English'
  },
  {
    value: 'jp',
    label: '日本語'
  },
  {
    value: 'kr',
    label: '한국어'
  }
]

// 语言包
const messages = {
  en: {
    ...elementEnLocale
  },
  sc: {
    ...elementZhLocale
  },
  tc: {
    ...elementZhTwLocale
  },
  ja: {
    ...elementJaLocale
  },
  kr: {
    ...elementKoLocale
  }
}

export function getLanguage() {
  const chooseLanguage = localStorage.getItem('locale')
  if (chooseLanguage) return chooseLanguage

  // if has not choose language
  const language = (navigator.language || navigator.browserLanguage).toLowerCase()
  const locales = Object.keys(messages)
  for (const locale of locales) {
    if (language.indexOf(locale) > -1) {
      return locale
    }
  }
  return 'jp'
}


// 重新加载国际化
export async function reloadMessage() {
  const dict = await loadDict()
  for (var lanObj of supportLans) {
    regMessage(lanObj.value, dict.data)
  }
  return messages
}

// 注册语言包
export async function regMessage(lan, lanDict) {
  if (!lan || !lanDict) {
    return
  }
  if (lan === 'sc') {
    messages['sc'] = {
      ...lanDict,
      ...elementZhLocale
    }
  } else if (lan === 'tc') {
    messages['tc'] = {
      ...lanDict,
      ...elementZhTwLocale
    }
  } else if (lan === 'en') {
    messages['en'] = {
      ...lanDict,
      ...elementEnLocale
    }
  } else if (lan === 'jp') {
    messages['jp'] = {
      ...lanDict,
      ...elementJaLocale
    }
  } else if (lan === 'kr') {
    messages['kr'] = {
      ...lanDict,
      ...elementKoLocale
    }
  }
}

// 网络加载国际化译文
export async function loadDict() {
// Vue.nextTick(() => {
//   console.log('18n执行速度',router.app._route);
// })
  
  const url = `/common/i18n?lang=${getLanguage()}`
  try {
    return ax.get(url).then(res => res.data)
  } catch (err) {
    console.log(err)
    return {}
  }
}

// reloadMessage()

// 可以在初始化VueI18n实例时，通过 localStorage 来为 locale 对象赋值
console.log('alskdnakl',getLanguage());
const i18n = new VueI18n({
  // locale: localStorage.getItem('locale') || 'ja',
  locale: getLanguage() || 'jp',
  fallbackLocale: 'sc',
  formatFallbackMessages: true,
  messages,
  // 屏蔽console的warn
  silentTranslationWarn: true
})

// locale.i18n((key, value) => i18n.t(key, value)) //为了实现element插件的多语言切换

export default i18n