import Vue from 'vue'
import {
  // Button as VanButton,
  // NavBar as VanNavBar,
  // Icon as VanIcon,
  Image as VanImage,
  // Swipe as VanSwipe,
  // SwipeItem as VanSwipeItem,
  // NoticeBar as VanNoticeBar,
  // Tab as VanTab,
  // Tabs as VanTabs,
  // Card as VanCard,
  // Tag  as VanTag,
  // Col as VanCol,
  // Row as VanRow,
  Popover as VanPopover,
  Checkbox as VanCheckbox
} from 'vant'

Vue.use(VanCheckbox)
// Vue.use(VanButton)
// Vue.use(VanNavBar)
// Vue.use(VanIcon)
Vue.use(VanImage)
// Vue.use(VanSwipe)
// Vue.use(VanSwipeItem)
// Vue.use(VanNoticeBar)
// Vue.use(VanTab)
// Vue.use(VanTabs)
// Vue.use(VanCard)
// Vue.use(VanTag)
// Vue.use(VanCol)
// Vue.use(VanRow)
Vue.use(VanPopover)
