import Vue from 'vue'
// Loading, Cascader, InfiniteScroll, Tabs, Carousel, CarouselItem, TabPane, InputNumber, Pagination, Button,  MessageBox, Message,  Divider, Input, Row, Col, Icon, Rate, Dropdown, DropdownMenu, DropdownItem, Menu, Submenu, MenuItem, Link, Select, Option, Form, FormItem, Drawer, Checkbox, Table, TableColumn, 
import { Carousel, CarouselItem, Image, Button, Collapse, CollapseItem, Backtop, Icon } from 'element-ui'

// Vue.use(Table)
// Vue.use(TableColumn)
// Vue.use(Cascader)
// Vue.use(InfiniteScroll)
// Vue.use(Pagination)
// Vue.use(InputNumber)
Vue.use(Button)
// Vue.use(Tabs)
// Vue.use(TabPane)
// Vue.use(Divider)
Vue.use(Image)
// Vue.use(Input)
// Vue.use(Row)
// Vue.use(Col)
Vue.use(Icon)
// Vue.use(Rate)
// Vue.use(Dropdown)
// Vue.use(DropdownMenu)
// Vue.use(DropdownItem)
// Vue.use(Menu)
// Vue.use(Submenu)
// Vue.use(MenuItem)
// Vue.use(Link)
// Vue.use(Select)
// Vue.use(Option)
// Vue.use(Form)
// Vue.use(FormItem)
Vue.use(Carousel)
Vue.use(CarouselItem)
// Vue.use(Drawer)
Vue.use(Collapse)
Vue.use(CollapseItem)
// Vue.use(Checkbox)
Vue.use(Backtop)
// Vue.use(Loading.directive)
// Vue.prototype.$loading = Loading.service
// Vue.prototype.$MessageBox = MessageBox
// Vue.prototype.$message = Message
// Vue.directive('infinite-scroll', InfiniteScroll)